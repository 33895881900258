<template>
  <div
    :style="styles"
    class="loader"
  ></div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    color: { default: '#64C7FF', type: String },
    size: {
      default: '50px',
      type: String
    }
  },
  computed: {
    styles () {
      return {
        background: `conic-gradient(#0000 10%, ${this.color}) content-box`,
        width: this.size
      }
    }
  }
}
</script>

<style scoped>
  /*from: https://css-loaders.com/spinner/*/
  .loader {
    --b: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: l4 1s infinite steps(10);
    box-sizing: border-box;
  }
  @keyframes l4 {
    to {
      transform: rotate(1turn)
    }
  }
</style>