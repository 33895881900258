<template>
  <div>
    <form>
      <input
        :disabled="disabled"
        :style="styles"
        :placeholder="placeholder"
        @change="onChange"
        :type="type"
        :id="id"
        autocomplete="on"
      />
      <label
        v-if="required"
        :for="id"
      ></label>
    </form>
    <div class="input-error">{{ error }}</div>
  </div>
</template>

<script>

export default {
  name: 'Input',
  props: {
    width: String,
    placeholder: String,
    disabled: Boolean,
    rules: { type: Array },
    type: String,
    id: { type: String },
    required: Boolean
  },
  data () {
    return {
      error: ''
    }
  },
  computed: {
    styles () {
      return {
        width: this.width
      }
    }
  },
  methods: {
    onChange ($event) {
      this.rules?.length && this.validate($event.target.value)
      this.$emit('change', $event.target.value)
    },
    validate (value) {
      for (const rule of this.rules) {
        const result = rule(value)
        if (result !== true) {
          this.error = result
          return result
        } else {
          this.error = ''
        }
      }
      return true
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
  input {
    position: relative;
    border-radius: $border-radius;
    background-color: $input-background;
    height: $height-input;
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    padding-left: 7px;
  }

  input:focus-visible {
    border: unset;
    outline: unset;
  }

  input::placeholder {
    color: $placeholder;
    padding-left: 3px;
  }

  .input-error {
    color: #e83f3f;
    text-transform: lowercase;
    margin-left: 2px;
    font-size: 12px;
  }

  label::before {
    content: '';
    position: absolute;
    margin-left: 2px;
    transform: translateY(130%);
    width: 12px; /* Размер иконки */
    height: 12px; /* Размер иконки */
    background-image: url('../../../assets/icons/required.svg'); //note: от этого и от иконки в ассетах потом избавиться
    background-size: cover;
  }
</style>