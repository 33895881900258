<template>
  <button
    :disabled="disabled"
    :style="styles"
    :class="loading ? 'loading' : ''"
    @click="$emit('click', $event)"
  >
    <slot
      v-if="!loading"
      name="content"
    ></slot>
    <Loader
      :color="color"
      v-if="loading"
      size="25px"
    />
  </button>
</template>

<script>
import Loader from '@/components/base/UI/Loader'

export default {
  name: 'Button',
  components: { Loader },
  props: {
    color: { type: String, default: 'white' }, //цвет текста и загрузки
    backgroundColor: { type: String, default: '#64C7FF' },
    disabled: Boolean,
    text: String,
    height: { type: String, default: '40px' },
    width: String,
    border: Boolean,
    loading: Boolean
  },
  computed: {
    additionalStyles () {
      const result = {}
      if (this.border) {
        result.border = '1px solid ' + this.color
      }
      return result
    },
    styles () {
      return {
        color: this.color,
        backgroundColor: this.backgroundColor,
        height: this.height,
        width: this.width,
        ...this.additionalStyles
      }
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
  button {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: 0.3px;
    display: flex;
    justify-content: center;
  }

  .loading {
    padding-top: 6px;
  }

  button:disabled :not(.loading){
    background-color: $disabled-background !important;
  }
</style>